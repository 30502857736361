import { createSlice } from "@reduxjs/toolkit";

const kycSlice = createSlice({
name : "KycVisibility",
initialState: [],
reducers : {
    ShowComp(state,action){
//  console.log(action.payload);

//  state.pop();
//  state.push(action.payload)
//  console.log(state);
    } 
    ,
    hideComp(state,action){
// state.pop();
// state.push(action.payload)

// console.log(state);
// console.log(action.payload);


    }
}
})

export default kycSlice;

export const {ShowComp,hideComp} = kycSlice.actions;
