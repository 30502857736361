
import { IoIosInfinite } from "react-icons/io";





  const   CardsData = [
    {
        status: "Staking Live",
        minStake: "100",
        maxStake: <IoIosInfinite />,
        lockTime: "12 months",
        apy: "100 %",
        link : "/dexview/100_APY",
        name:"Triton-I"
    },
    {
        status: "Staking Live",
        minStake: "100",
        maxStake: <IoIosInfinite />,
        lockTime: "18 months",
        apy: "150 %",
        link : "/dexview/150_APY",
        name:"Triton-II"

    }
    ,
    {
        status: "Staking Live",
        minStake: "100",
        maxStake: <IoIosInfinite />,
        lockTime: "24 months",
        apy: "200 %",
        link : "/dexview/200_APY",
        name:"Triton-III"

    }
]

export default CardsData;