import React from 'react'
import zx from "./Features.module.css"
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

const Fetaures = () => {
  return (
    <div  className={zx.Fetaures}  data-aos="fade-in"  >
    <ToastContainer style={{ fontSize: "1.4rem" }} />

{/* <div className={zx.Fetaures1}  >
<img alt='Sikka'  src={require("../Media/bitcoin.png")}  ></img>
</div> */}


{/* CONTENT RIGHT  */}
<div className={zx.Fetaures2}   >

<p  className={zx.Fetaures2p1}  data-aos="flip-down" >
 New Generation Modern Technology</p>
<p  className={zx.Fetaures2p2} data-aos="flip-up"  >
The World’s 1st Immortal Platform Offering Rewards makes it easy for investors to buy and sell their tokens.<wbr></wbr>
 It offers you the easiest way to get fast and secure crypto money and tokens.</p>



<div className={zx.Fetaures3}  >

{/* 4 childs  */}
<div  className={`${zx.Fetaures3ch1} d-flex`}  data-aos="flip-down"  >
<span  className={zx.Fetaures3ch1span}    >
  {/* <img  alt='' src={require("../Media/stakei.png")}  ></img> */}
  <img  alt='' src={require("../Media/stki.png")}  ></img>
</span>
<div  className={`${zx.Fetaures3ch1cont} p-3 ps-4`}    >
<h3  className={zx.h2w}   >Staking</h3>
  <p  className={zx.h2q}   >Want a paasive income ?  
  Let your money work for you, earn high APY on your funds, just by staking Sikka coin  . </p>
  <Link  to='/Staking'  className={zx.exploremore}   >Explore now</Link>
</div>

</div>


{/* 2 */}
<div  className={`${zx.Fetaures3ch1} d-flex`} data-aos="flip-up"  >
<span  className={zx.Fetaures3ch1span}    >
  <img  alt='' src={require("../Media/launchpad.png")}  ></img>
</span>
<div  className={`${zx.Fetaures3ch1cont} p-3 ps-4`}    >
<h3  className={zx.h2w}   >Launchpad</h3>
  <p  className={zx.h2q}   >Sikka launchpad  enable blockchain-based companies to list their token with ease .</p>
  <Link  onClick={()=>toast("Comming Soon")}  className={zx.exploremore}   >Explore now</Link>

</div>

</div>


</div>


</div>

    </div>
  )
}

export default Fetaures