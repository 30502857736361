import React, { useEffect, useState } from 'react'
import sss from "./List.module.css"

const List = () => {


    const [btc, setBtc] = useState('');
    const [usdt, setUsdt] = useState('');
    const [sol, setSol] = useState('');
    const [xrp, setXrp] = useState('');
    const [ada, setAda] = useState('');
    const [link, setLink] = useState('');
    const [dot, setDot] = useState('');
    const [matic, setmatic] = useState('');
    const [doge, setDoge] = useState('');

    const [eth, setEth] = useState('');
    const [trx, settrx] = useState('');
    const [bnb, setBnb] = useState('');



    // BTC,USDT,SOL,XRP,ADA,LINK,DOT,MATIC,DOGE,ETH,TRX,BNB

    // api to fetch coins 
    useEffect(() => {
        const fetchTokenPrice = async () => {
            try {
                const data = await fetch("https://min-api.cryptocompare.com/data/pricemultifull?fsyms=BTC,USDT,SOL,XRP,ADA,LINK,DOT,MATIC,DOGE,ETH,TRX,BNB&tsyms=USD");
                const response = await data.json();

                console.log(response, "response it with me");
                if (response) {
                    if (response.DISPLAY) {
                        setBtc(response.DISPLAY.BTC.USD);
                        setEth(response.DISPLAY.ETH.USD);
                        setBnb(response.DISPLAY.BNB.USD);
                        setXrp(response.DISPLAY.XRP.USD);
                        setUsdt(response.DISPLAY.USDT.USD);
                        setSol(response.DISPLAY.SOL.USD);
                        setmatic(response.DISPLAY.MATIC.USD);
                        setAda(response.DISPLAY.ADA.USD);
                        settrx(response.DISPLAY.TRX.USD);
                        setDot(response.DISPLAY.DOT.USD);
                        setDoge(response.DISPLAY.DOGE.USD);
                        setLink(response.DISPLAY.LINK.USD);
                    }
                }



            }
            catch (err) {
                console.log(err);
            }
        }
        fetchTokenPrice();
    },
        [])


    return (
        <div className={`${sss.List}`} data-aos="fade-up" >

            <div className={`${sss.List1}`} data-aos="flip-up">
                Crypto Current Tokens
            </div>

            <div className={`${sss.List2}`} data-aos="flip-down" >
                Follow the exchange <wbr></wbr>rates of current <wbr></wbr>cryptocurrencies and tokens.
            </div>

            <div className={`${sss.List3}`} >


                {/* 1 */}

                {btc ? <div className={`${sss.List3childs} d-flex gap-0 align-items-center`} data-aos="flip-down" >
                    <div className={`${sss.List3childsa}`}   >
                        <img className={`${sss.List3childsaimg}`} src={require("../Media/btc.png")} alt='icc'   ></img>
                    </div>
                    <div className={`${sss.List3childsb} `}   >
                        <div className={`${sss.List3p1} text-white`}   > Bitcoin</div>
                        <div className={`${sss.List3p2} text-white`}   > {btc ? btc.PRICE : 0}</div>
                        {/* <div className={`${sss.List3p3} text-secondary`}   > BTC </div> */}
                    </div>
                </div>
                    : null
                }
                {/* 2 */}

                {eth ? <div className={`${sss.List3childs} d-flex gap-0 align-items-center`} data-aos="flip-down" >
                    <div className={`${sss.List3childsa}`}   >
                        <img className={`${sss.List3childsaimg}`} src={require("../Media/eth.png")} alt='icc'   ></img>
                    </div>
                    <div className={`${sss.List3childsb} `}   >
                        <div className={`${sss.List3p1} text-white`}   > Ethereum</div>
                        <div className={`${sss.List3p2} text-white`}   > {eth ? eth.PRICE : 0}</div>
                        {/* <div className={`${sss.List3p3} text-secondary`}   > ETH </div> */}
                    </div>
                </div>
                    : null
                }
                {/* 3 */}

                {bnb ?
                    <div className={`${sss.List3childs} d-flex gap-0 align-items-center`} data-aos="flip-up"  >
                        <div className={`${sss.List3childsa}`}   >
                            <img className={`${sss.List3childsaimg}`} src={require("../Media/bnbs.png")} alt='icc'   ></img>
                        </div>
                        <div className={`${sss.List3childsb} `}   >
                            <div className={`${sss.List3p1} text-white`}   > Binance Coin</div>
                            <div className={`${sss.List3p2} text-white`}   > {bnb ? bnb.PRICE : 0}</div>
                            {/* <div className={`${sss.List3p3} text-secondary`}   > BNB </div> */}
                        </div>
                    </div>
                    : null
                }



                {/* 5 */}


                {
                    xrp ?
                        <div className={`${sss.List3childs} d-flex gap-0 align-items-center`} data-aos="flip-down"   >
                            <div className={`${sss.List3childsa}`}   >
                                <img className={`${sss.List3childsaimg}`} src={require("../Media/xrp.png")} alt='icc'   ></img>
                            </div>
                            <div className={`${sss.List3childsb} `}   >
                                <div className={`${sss.List3p1} text-white`}   >
                                    XRP</div>
                                <div className={`${sss.List3p2} text-white`}   > {xrp ? xrp.PRICE : 0}</div>
                                {/* <div className={`${sss.List3p3} text-secondary`}   > XRP </div> */}
                            </div>
                        </div>
                        : null
                }

                {/* 6 */}
                {
                    usdt ?
                        <div className={`${sss.List3childs} d-flex gap-0 align-items-center`} data-aos="flip-down"   >
                            <div className={`${sss.List3childsa}`}   >
                                <img className={`${sss.List3childsaimg}`} src={require("../Media/usdt.png")} alt='icc'   ></img>
                            </div>
                            <div className={`${sss.List3childsb} `}   >
                                <div className={`${sss.List3p1} text-white`}   > Tether</div>
                                <div className={`${sss.List3p2} text-white`}   > {usdt ? usdt.PRICE : 0}</div>
                                {/* <div className={`${sss.List3p3} text-secondary`}   > USDT </div> */}
                            </div>
                        </div>
                        : null
                }

                {/* /8 */}
                {sol ?
                    <div className={`${sss.List3childs} d-flex gap-0 align-items-center`} data-aos="flip-up" >
                        <div className={`${sss.List3childsa}`}   >
                            <img className={`${sss.List3childsaimg}`} src={require("../Media/solana.png")} alt='icc'   ></img>
                        </div>
                        <div className={`${sss.List3childsb} `}   >
                            <div className={`${sss.List3p1} text-white`}   > Solana</div>
                            <div className={`${sss.List3p2} text-white`}   > {sol ? sol.PRICE : 0}</div>
                            {/* <div className={`${sss.List3p3} text-secondary`}   > SOL </div> */}
                        </div>
                    </div>
                    : null
                }

                {/* 9/ */}
                {ada ?
                    <div className={`${sss.List3childs} d-flex gap-0 align-items-center`} data-aos="flip-up" >
                        <div className={`${sss.List3childsa}`}   >
                            <img className={`${sss.List3childsaimg}`} src={require("../Media/ada.png")} alt='icc'   ></img>
                        </div>
                        <div className={`${sss.List3childsb} `}   >
                            <div className={`${sss.List3p1} text-white`}   > Cardano</div>
                            <div className={`${sss.List3p2} text-white`}   > {ada ? ada.PRICE : 0}</div>
                            {/* <div className={`${sss.List3p3} text-secondary`}   > ADA  </div> */}
                        </div>
                    </div>
                    : null
                }


                {/* 10/ */}
                {matic ?
                    <div className={`${sss.List3childs} d-flex gap-0 align-items-center`} data-aos="flip-up" >
                        <div className={`${sss.List3childsa}`}   >
                            <img className={`${sss.List3childsaimg}`} src={require("../Media/polygon.png")} alt='icc'   ></img>
                        </div>
                        <div className={`${sss.List3childsb} `}   >
                            <div className={`${sss.List3p1} text-white`}   > Matic</div>
                            <div className={`${sss.List3p2} text-white`}   >{matic ? matic.PRICE : 0}</div>
                            {/* <div className={`${sss.List3p3} text-secondary`}   > Polygon </div> */}
                        </div>
                    </div>
                    : null
                }

                {/* 11/ */}
                {
                    trx ?
                        <div className={`${sss.List3childs} d-flex gap-0 align-items-center`} data-aos="flip-up" >
                            <div className={`${sss.List3childsa}`}   >
                                <img className={`${sss.List3childsaimg}`} src={require("../Media/tron.png")} alt='icc'   ></img>
                            </div>
                            <div className={`${sss.List3childsb} `}   >
                                <div className={`${sss.List3p1} text-white`}   > Tron</div>
                                <div className={`${sss.List3p2} text-white`}   >{trx ? trx.PRICE : 0}</div>
                                {/* <div className={`${sss.List3p3} text-secondary`}   > Trx </div> */}
                            </div>
                        </div>
                        : null
                }


            {/* 11/ */}
            {
                    link ?
                        <div className={`${sss.List3childs} d-flex gap-0 align-items-center`} data-aos="flip-up" >
                            <div className={`${sss.List3childsa}`}   >
                                <img className={`${sss.List3childsaimg}`} src={require("../Media/chainlink.png")} alt='icc'   ></img>
                            </div>
                            <div className={`${sss.List3childsb} `}   >
                                <div className={`${sss.List3p1} text-white`}   > Link</div>
                                <div className={`${sss.List3p2} text-white`}   >{link ? link.PRICE : 0}</div>
                                {/* <div className={`${sss.List3p3} text-secondary`}   > Trx </div> */}
                            </div>
                        </div>
                        : null
                }

                        {/* 11/ */}
            {
                    dot ?
                        <div className={`${sss.List3childs} d-flex gap-0 align-items-center`} data-aos="flip-up" >
                            <div className={`${sss.List3childsa}`}   >
                                <img className={`${sss.List3childsaimg}`} src={require("../Media/polkadot.png")} alt='icc'   ></img>
                            </div>
                            <div className={`${sss.List3childsb} `}   >
                                <div className={`${sss.List3p1} text-white`}   > Dot</div>
                                <div className={`${sss.List3p2} text-white`}   >{dot ? dot.PRICE : 0}</div>
                                {/* <div className={`${sss.List3p3} text-secondary`}   > Trx </div> */}
                            </div>
                        </div>
                        : null
                }

                {
                    doge ?
                        <div className={`${sss.List3childs} d-flex gap-0 align-items-center`} data-aos="flip-up" >
                            <div className={`${sss.List3childsa}`}   >
                                <img className={`${sss.List3childsaimg}`} src={require("../Media/doge.png")} alt='icc'   ></img>
                            </div>
                            <div className={`${sss.List3childsb} `}   >
                                <div className={`${sss.List3p1} text-white`}   > Doge</div>
                                <div className={`${sss.List3p2} text-white`}   >{doge ? doge.PRICE : 0}</div>
                                {/* <div className={`${sss.List3p3} text-secondary`}   > Trx </div> */}
                            </div>
                        </div>
                        : null
                }




                {/* map here   */}
            </div>
        </div>
    )
}

export default List