import React from 'react'
// import Intro from '../Intro/Intro'
// import Info from '../Info/Info'
// import Nomics from '../Tokenomics/Nomics'
// import Roadmap from '../Roadmap/Roadmap'
// import Faq from '../Faq/Faq'
// import Burning from '../Burning/Burning'
import Footer from '../Footer/Footer'
// import Roadmap1 from '../Roadmap2/Roadmap'
import Footerlag from '../Footer/Footerlag'
import "./Home.css"
import Navbar from '../Navbar/Navbar'
// import Login from '../Login/Login'
// import Layout from '../utilities/Layout'

import Intro from '../Intro/Intro'
// import Play from '../playwatch/Play'
import Fetaures from '../Utilityo/Fetaures'
import List from '../trade/List'
import Contract from '../Contract/Contract'


const Home = () => {
  

  



  return (
    <div  className='homeCont'  >
    <Navbar></Navbar>
    <Intro></Intro>
    {/* <Fetaures></Fetaures> */}

    <List></List>

<Contract></Contract>

    <Fetaures></Fetaures>

     <Footer></Footer>
     <Footerlag></Footerlag>


    </div>
  )
}

export default Home