import React from 'react'
import { NavLink } from 'react-router-dom'

const Nomatch = () => {

  const yuobtn = {
    width:"26rem",
    background:"var(--golden)",
    fontSize:"1.42rem"
  }


  return (
    <div className='d-flex justify-content-center align-items-center flex-column bg-none text-white' style={{width:"100vw",height:"100vh",fontSize:"2.6rem",background:"none !important",color:"white"}}  >No Match Found ! <br></br>
    
 <NavLink  style={yuobtn} to='/' className='text-dark btn mt-3 p-3'  >Go back Home</NavLink>
    
      </div>
  )
}

export default Nomatch