import React, { useState } from 'react'
import "./Footer.css"
import { NavLink, useNavigate } from 'react-router-dom'
import pdf from "../Media/Sikka.pdf"


const Footer = () => {

    const [emailguio, setEmailguio] = useState('')

    // var d0  = JSON.parse(localStorage.getItem('user_Verify_Dataauth_token'))
    // var d1  = JSON.parse(localStorage.getItem('user_Verify_Datais_admin'))
    // var d11  = JSON.parse(localStorage.getItem('user_Verify_Datakyc_status'))
    // var d111  = JSON.parse(localStorage.getItem('user_Verify_Datauser_id'))


    const navigate = useNavigate()


    const HomeErra = () => {
        navigate("/");
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 100)
    }


    return (
        <div className='foot_container  container-fluid' >

            {/* 1e  */}
            <div className='foot_e1 d-flex justify-content-around align-items-center flex-column' >



                {/* childs  1e1*/}
                <div className='child_e1' >

                    <span className='fw-normal text-uppercase'>
                       Sikka Coin
                    </span>
                    <hr className='text-white'></hr>
                    <span className='fw-normal fsize' >Follow us on </span>
                </div>

                {/* childs  1e2*/}

                <div className='child_e1 ' >




                    <div className='fs-holder'>
                        <NavLink  target='_blank' to={"https://www.facebook.com/?sk=welcome&email_confirmed=sikkacoin82%40gmail.com"} ><i className="fa-brands fa-facebook-f"></i></NavLink>
                        <NavLink  target='_blank'  to={"https://www.instagram.com/sikka.coin/"}  >   <i className="fa-brands fa-instagram"></i></NavLink>
                        <NavLink  target='_blank' to={"https://youtube.com/@SikkaCoin?si=mruwK3DODbFTEk0C"}  ><i className="fa-brands fa-youtube"></i></NavLink>
                        {/* <NavLink  target='_blank'      ><i className="fa-brands fa-telegram"></i></NavLink> */}
                        <NavLink  target='_blank'  to={"https://twitter.com/Sikka_Coin"}   ><i className="fa-brands fa-twitter"></i></NavLink>
                        <NavLink  target='_blank' to={"https://www.linkedin.com/in/sikka-coin-21321b2a7/"}    ><i className="fa-brands fa-linkedin"></i></NavLink>
                    </div>

                </div>

            </div>






            {/* 2e */}
            <div className='foot_e2' >


                {/* childs  1e1*/}
                <div className='child_e1' >
                    <div className='link_hy fw-normal '>
                        Links
                    </div>
                    <div className='link_links' >
                        <ul className='g_link'>
                            <li className='nostyle gfh ls2' onClick={() => HomeErra()} >Home</li>

                            <li><a className='nostyle fill_pp text-white ls2  fw-normal'
                             target='_blank' download="Sikka Whitepaper"    href={pdf} 
                            >Whitepaper</a></li>
                            <li><NavLink className='nostyle ls2 fw-normal' to='/TermsAndCondition'   >Terms & Condition</NavLink></li>


                        </ul>
                    </div>

                </div>

                {/* childs  1e2*/}

                <div className='child_e1' >
                    <div className='link_hy fw-normal '>
                        Links
                    </div>
                    <div className='link_links typou' >
                        <ul className='g_link'>
                            {/* <li><NavLink to="/trade" className='nostyle ls2 fw-normal ls2'  >Trade</NavLink></li> */}
                            <li><NavLink to="/Staking" className='nostyle ls2 fw-normal '  >Stake</NavLink></li>
                            <li><NavLink onClick={() => alert("Comming soon")} className='nostyle ls2 fw-normal'   >Wallet</NavLink></li>
                            {/* <li onClick={()=>alert("Please use our social handles.")}  >Contact us</li> */}



                        </ul>
                    </div>

                </div>


            </div>



            {/* 3e */}
            <div className='foot_e3' >
                <div className='email_con d-flex justify-content-around align-items-center flex-column' >
                    <div className='label_community fw-normal' >
                        Unlock Exclusive Perks: Subscribe & Empower Your Future!
                    </div>

                    {/* email input  */}
                    <div className="input-group mb-3 sub_email">
                        <span className="input-group-text bg-light trdf gfh bhd" id="basic-addon1">@</span>
                        <input

                            value={emailguio} onChange={(e) => setEmailguio(e.target.value)}

                            id='ghyuinput' type="text" className="form-control  trdf gfh" placeholder="" aria-label="Username" aria-describedby="basic-addon1" />
                        <span className="input-group-text gfh trdf" id="basic-addon2" onClick={() => { alert("submitted"); setEmailguio('') }}><i className="fa-regular fa-paper-plane gfh bhd"></i></span>

                    </div>


                    <div className='support_f text-white' >
                        {/* Have any query ? <a className='nostyle text-primary' >Click here</a> */}
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Footer


