import React from 'react'
import "./Contract.css"

const Contract = () => {

  const ClipBoard = (data) => {

    window.navigator.clipboard.writeText(data).then(()=>{
      alert(" Copied : " + data )
    }).catch(err=>{alert(err)})


  }


  const ContractAddress = "0xcca556aecf1e8f368628c7543c382303887265ed" ;

  return (
    <div className='contract_div' data-aos="fade-down" >
    {/* heading div *2 */}
<div className='heading_contract d-flex flex-column' >
  <div id='bn' className='contract_h1 color_gradientbb '>Token Contract</div>
  <div id='bm' className='contract_h2 color_gradientbb'>Use the contract information below to add Sikka COin token to your wallet.</div>
</div>


{/* contract_pannel *4/ */}
<div className='contract_pannel  d-flex ' >

{/* contract address  2*/}
<div className=' d-flex flex-column color_gradient align-items-center  c_pannel_1  ' >
  <div className='con_address color_gradientr fw-400 text-white' >Address</div>
  <div className=' con_address_c color_gradientr text-white' >{ContractAddress}<i className="fa-regular fa-clone ps-3  lightit" onClick={()=>ClipBoard(ContractAddress)}></i></div>
</div>


{/* decimal / */}
<div className=' c_pannel_2  ' >
<div className='c_pannel_1a color_gradientr text-white' >Decimal</div>
  <div className=' c_pannel_1b color_gradientr text-white' >18 </div>
</div>

{/* network  */}
<div className=' c_pannel_3 ' >
<div className=' c_pannel_1a color_gradientr text-white' >Network</div>
  <div className=' c_pannel_1b c_pannel_1bb color_gradientr text-white' >BNB Smart Chain</div>
</div>

{/* token ticker  */}
<div className=' c_pannel_4 ' >
<div className=' c_pannel_1a c_pannel_1aa color_gradientr text-white  fw-200' >Token Symbol</div>
  <div className=' c_pannel_1b c_pannel_1bbb color_gradientr text-white fw-200' >Sikka Coin </div>
</div>

</div>


    </div>
  )
}

export default Contract