import React from 'react'
import { NavLink } from 'react-router-dom'

const Error401 = () => {

    const yuobtn = {
        width:"26rem",
        background:"blue",
        fontSize:"1.42rem"
      }
    


    return (
        <div style={{ width: "100vw", height: "100vh" }} className='display-3 d-flex justify-content-center align-items-center text-white'  >
            <div
    style={{width:"100vw",minHeight:"4%",textAlign:"center"}}> Unauthorized access.</div>
        <div
    style={{width:"100vw",minHeight:"4%",textAlign:"center"}}>Login and try again.</div>
    
    <NavLink  style={yuobtn} to='/' className='text-white btn mt-3 p-3'  >Go back Home</NavLink>
    
    </div>
    
        
        
        

    )
}

export default Error401