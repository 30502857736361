import React, { Suspense, lazy } from 'react'
import "./App.css"
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom';
import Home from './Component/Home/Home';
import Nomatch from './Component/error/Nomatch';
import Error400 from './Component/error/Error400';
import Error401 from './Component/error/Error401';
import Error404 from './Component/error/Error404';
import Error500 from './Component/error/Error500';
import LogOutSpin from './Component/Logout/LogOut';
import TermsCondition from './Component/TermsCondition';
import Staking from './Component/Staking/pages/Home';
// import Dex1 from './Component/Staking/pages/Dex1';
// import Dex2 from './Component/Staking/pages/Dex2';
// import Dex3 from './Component/Staking/pages/Dex3';
// import StakingHistory from  "./Component/Staking/pages/StakingHistory"
// import TotalReferral from './Component/Staking/pages/TotalReferral';
// import TeamIncome from './Component/Staking/pages/TeamIncome';


// const Staking = lazy(() => import('./Component/Staking/pages/Home'));

const Dex1 = lazy(() => import('./Component/Staking/pages/Dex1'));
const Dex2 = lazy(() => import('./Component/Staking/pages/Dex2'));
const Dex3 = lazy(() => import('./Component/Staking/pages/Dex3'));

const StakingHistory = lazy(() => import("./Component/Staking/pages/StakingHistory"))
const TotalReferral = lazy(() => import('./Component/Staking/pages/TotalReferral'));
const TeamIncome = lazy(() => import('./Component/Staking/pages/TeamIncome'));





const App = () => {
  return (
    <div>
      <Suspense fallback={<LogOutSpin Staking={''}   ></LogOutSpin>}   >
        <BrowserRouter>

          <Routes>
            <Route path='/' element={<Home></Home>} />
            <Route path='/Staking' element={<Staking></Staking>}  ></Route>
            {/* <Route path='/IccBank' element={<IccBanking></IccBanking>}  ></Route> */}
            {/* 1-7  */}

            <Route path='/TermsAndCondition' element={<TermsCondition></TermsCondition>}   ></Route>
            {/* <Route path="dexview" element={<Dexview />} /> */}
            <Route path="/StakeHistory" element={<StakingHistory></StakingHistory>} />


            <Route path='/dexview/100_APY' element={<Dex1></Dex1>}  ></Route>
            <Route path='/dexview/150_APY' element={<Dex2></Dex2>}  ></Route>
            <Route path='/dexview/200_APY' element={<Dex3></Dex3>}  ></Route>
            {/* <Route  path='/StakeHistory'  element={<StakingHistory></StakingHistory>}  ></Route> */}
            <Route path='/TotalReferrals' element={<TotalReferral></TotalReferral>}  ></Route>
            <Route path='/Teamincome' element={<TeamIncome></TeamIncome>}  ></Route>


            <Route path='/' element={<Error500></Error500>}   ></Route>
            <Route path='/' element={<Error400></Error400>}   ></Route>
            <Route path='/' element={<Error401></Error401>}   ></Route>
            <Route path='/' element={<Error404></Error404>}   ></Route>
            <Route path='*' element={<Nomatch></Nomatch>}   ></Route>
          </Routes>

        </BrowserRouter>
      </Suspense>
    </div>
  )
}

export default App