import React, { lazy, useState } from 'react'
import asx from "./Play.module.css"
import { Link } from 'react-router-dom'
import pdf from "../Media/Sikka.pdf"


const Play = () => {

    const [showPopUp, setShowPopUp] = useState(0)


  return (


    <>

{
    showPopUp?
    <div   className={asx.PlayVideoPopUp}   >
<div  className={asx.PlayVideoPopUpClo}     >
{/* closer  */}
<i className="fa-solid fa-xmark cursor-pointer" onClick={()=>setShowPopUp(0)}></i>

{/* video player  */}
</div>
<div   className={asx.VideoPlayer}   >
    {/* <video   src='https://youtu.be/S3uTR-P0ycA?si=xZvmVywbk9NBlFRw'  autoPlay   ></video> */}
</div>

</div>: null
}



{/* <div  className='position-absolute top-10 right10 drop-shadow-4 polytetra'    ><img className='wd-10' src={require("../Media/s1.png")}   ></img>
    </div> */}

{/* pop up  */}


    <div className={asx.Play}    >
<div className={asx.Play1}     >
<p     >Coin Sikka- A Multifaceted Defi Platform </p>
<p  >Do not be late to buy our high profit tokens. </p>
</div>

<div className={`${asx.Play2} d-flex justify-content-center align-items-center`} 

// aos 


 >



<button  data-aos="fade-right"   >
  Whitepaper<i className="fa-solid fa-arrow-right"></i>
</button>

<a        className={`${asx.Playbot} d-flex justify-content-center align-items-center`} 
href={pdf} download={"Sikka Whitepaper"} >
<i className="fa-solid fa-download"></i>
</a>


</div>
    </div>

    </>
  )
}

export default Play