import { createSlice } from "@reduxjs/toolkit";

const SignupSlice = createSlice({
    name: "SignupVisibility",
    initialState: [],
    reducers : {
        showSignup(state,action){
            console.log(action.payload);

state.pop();
state.push(action.payload)
        }
        ,
        hideSignUp(state,action){
            console.log(action.payload);

state.pop();
state.push(action.payload)
        }
    }
})



export default SignupSlice

export const {showSignup,hideSignUp} = SignupSlice.actions;
