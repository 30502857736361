import React from 'react'
import styles from "./Home.module.css"
import { TbHexagon3D } from 'react-icons/tb';
import { BsDot } from 'react-icons/bs';
import { AiOutlineBell } from 'react-icons/ai';

import { FcLikePlaceholder } from 'react-icons/fc';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import CardsData from './CardsData';
import Referral from './Referral';
import Navbar from '../../Navbar/Navbar';
import Footerlag from '../../Footer/Footerlag';
import { ethers } from 'ethers';
import IccToken from "../../../BlockchainApi/ICCToken.json";
import StakingABI from "../../../BlockchainApi/StakingIcc.json";






const Staking = () => {

  const navigate = useNavigate()
  const [tokenBalance, setTokenBalance] = useState("");
  const [DailyInterst, setDailyInterst] = useState("");




  const QuadStyle = {
    height: '35px', width: '35px', marginLeft: '-15px', marginTop: "2rem", color: "#f0b90b", backgroundColor: "black", borderRadius: "50%", border: "1px  solid #f0b90b", padding: "4px"
  }



  useEffect(() => {
    window.scrollTo(0, 10)
  }, [])

  
  const StakingContractAddress = "0x81eeCf723C4ec6587c694D7BB77428806B97930a";
  const IccTokenAddress = "0xcca556aecf1e8f368628c7543c382303887265ed";


// token balance 
  useEffect(() => {
    const fetchTokenBalance = async () => {

      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner();

        const StakingContract = new ethers.Contract(StakingContractAddress, StakingABI, signer);

        const IccTokenContract = new ethers.Contract(IccTokenAddress, IccToken, signer);

        // Get the user's address from MetaMask
        const accounts = await provider.listAccounts();


        const userAddress = accounts[0];
        console.log("userAddress======", userAddress)



        const tokenBalance = await IccTokenContract.balanceOf(userAddress);

        // Convert the balance to a human-readable format (e.g., wei to ether)

        const formattedBalance = ethers.utils.formatUnits(tokenBalance, 18);

        // Assuming 18 decimal places for the ERC20 token

        setTokenBalance(formattedBalance);
        console.log("formattedBalance===========", formattedBalance);




      }
      catch (error) {
        console.error('Error fetching token balance', error);
      }
    };

    fetchTokenBalance();


  }, []);

// token balance  format
 
  const formatBalance = (tokenBalance,) => {

    const [wholePart, decimalPart] = tokenBalance.split('.');
    const formattedWholePart = wholePart.slice(0, 6);
    const formattedDecimalPart = decimalPart ? decimalPart.slice(0, 5) : '0000';
    return `${formattedWholePart}.${formattedDecimalPart}`;
  };



  console.log(formatBalance(tokenBalance), "hello");



  // daily interst 

  
useEffect(() => {
  const fetchDailyInterst = async () => {

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner();

      const StakingContract = new ethers.Contract(StakingContractAddress, StakingABI, signer);

      const IccTokenContract = new ethers.Contract(IccTokenAddress, IccToken, signer);

      // Get the user's address from MetaMask
      const accounts = await provider.listAccounts();


      const userAddress = accounts[0];
      console.log("userAddress======", userAddress)



      const DailyInterst = await IccTokenContract.calculatePerDayInterest(userAddress);

      // Convert the balance to a human-readable format (e.g., wei to ether)

      const formattedInterst = ethers.utils.formatUnits(DailyInterst,18);

      // Assuming 18 decimal places for the ERC20 token

      setDailyInterst(formattedInterst);
      console.log("formattedInterst===========", formattedInterst);




    }
    catch (error) {
      console.error('Error fetching token balance', error);
    }
  };

  fetchDailyInterst();


}, []);

// token balance  format

const formatInterstApy = (DailyInterst,) => {

  const [wholePart, decimalPart] = DailyInterst.split('.');
  const formattedWholePart = wholePart.slice(0, 6);
  const formattedDecimalPart = decimalPart ? decimalPart.slice(0, 5) : '0000';
  return `${formattedWholePart}.${formattedDecimalPart}`;
};



console.log(formatInterstApy(DailyInterst), "daily interset");









  return (
    <>
      {/* navbar  */}
      <Navbar></Navbar>

      <div className={`${styles.top} `}>

        {/* referal  */}
        <Referral></Referral>




        <div className={styles.homeContainer}>




          {/* mapping cards here  */}
          {
            CardsData.map((data, i) => {
              return (

                <div className={styles.card} key={i}   >
                  <div className={styles.top_text}>

                    {/* images  */}
                    <div className={styles.imageContainer}>
                      <img src={require("../../Media/coin.gif")} alt="ICC" />

                      {/* bnb logo  */}
                      <TbHexagon3D style={QuadStyle} />
                    </div>

                    {/* active status / */}
                    <div className={styles.textContainer}>

                      <BsDot style={{ height: '30px', width: '40px', color: "#00c251" }} />

                      <span className={styles.StakingStatus}  > {data.status} </span>
                    </div>
                  </div>

                  {/* card name and bnb price per coin price  */}
                  <div className={styles.name}>
                    <div className={styles.nameBox}  >
                      <h3>{data.name}</h3>
                      {/* <p className={`${styles.oneBNB} text-white`}>1 BNB = 210.40 Sikka Coin</p> */}
                    </div>


                    {/* like and notification  icons  */}
                    <div className={`${styles.ApyBox} cursor-pointer`}  >

                      <AiOutlineBell style={{
                        height: "30px", width: "30px", border: "2px solid aqua", padding: ".5rem", borderRadius: "50%", display: "flex"
                        , justifyContent: "center", alignItems: "center"
                      }} />
                      <FcLikePlaceholder style={{
                        marginLeft: ".6rem", height: "30px", width: "30px", padding: ".6rem", display: "flex"
                        , justifyContent: "center", alignItems: "center", borderRadius: "50%", border: "1px solid aqua"
                      }} />

                    </div>


                    {/* min max value  */}
                  </div>

                  <hr className='text-info mt-1'  ></hr>

                  <div className={styles.soft}>
                    <h5 className='text-warning'>(Min - Max) Sikka Coin Staking </h5>
                    <p className={styles.fiftybnb}>{data.minStake} - {data.maxStake} <span className='fs-5 text-info' >Sikka Coin</span></p>
                  </div>

                  {/* line  */}
                  <hr className='text-info mt-1 mb-1'  ></hr>




                  {/* lockup time /  */}
                  <div className={`${styles.Lockup_time}   `}>
                    <div className={`${styles.LockUpCaption}`}  >Lockup Time :   </div>
                    <div className={`${styles.LockUpDuration}`}  >{data.lockTime}</div>

                  </div>


                  {/* daily interst  */}
                  <div className={`${styles.Lockup_time}   `}>

                    <div className={`${styles.LockUpCaption}`}  >Daily Interest :   </div>
                    <div className={`${styles.LockUpDuration}`}  >{formatInterstApy(DailyInterst) || 0}</div>
                  </div>

                  {/* apy  */}
                  <div className={`${styles.Lockup_time}   `}>

                    <div className={`${styles.LockUpCaption}`}  >APY :   </div>
                    <div className={`${styles.LockUpDuration}`}  >{data.apy}</div>
                  </div>

                  {/* line  */}
                  <hr className='text-info mt-2 mb-3'  ></hr>


                  {/* buy coin claim reward stake coin  */}
                  <div className={styles.domainBox}  >
                    <div className={styles.domainBtnBox} >

                      <button className={styles.domainbtn1}  > Buy ICC</button>
                      {/* <button className={styles.domainbtn2}  >Your Staking</button> */}
                      <button className={`${styles.domainbtn3} mt-3`} onClick={() => navigate(data.link)}  >Stake Sikka Coin </button>

                      {/* available balance  */}
                      <p className={`${styles.availableBalance} ps-2`}  ><span> Available Balance</span> : <span>{formatBalance(tokenBalance) || 0}</span></p>

                    </div>
                  </div>


                  {/* <div className={`${styles.closeEndLinks} `}  >

                    <Link className='d-block text-center '   >Know more</Link>
                    <Link className='d-block text-center '  >Buy Sikka Coin</Link>

                  </div> */}


                </div>

              )
            })



          }




        </div>
      </div>


      <Footerlag></Footerlag>
    </>
  )
}

export default Staking