import React, { useEffect, useState } from 'react'
import { Origin } from '../../Origin'
import zz from "./Referral.module.css"
import { ethers } from 'ethers';
import StakingABI from "../../../BlockchainApi/StakingIcc.json";


const Referral = () => {

  const [euserAddress, setEuserAddress] = useState('');

  const referralLink = `${Origin}dexview/100_APY?Ref=${euserAddress || 0}`


  const StakingContractAddress = "0x81eeCf723C4ec6587c694D7BB77428806B97930a";


  useEffect(() => {
    const fetchingUserAddress = async () => {
      // Create a provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();

      const StakingContract = new ethers.Contract(StakingContractAddress, StakingABI, signer);

      // Get the user's address from MetaMask
      const accounts = await provider.listAccounts();
      const userAddress = accounts[0]; // Ensure you have the user's address
      setEuserAddress(userAddress)
    }

    fetchingUserAddress()

  }, [])

  console.log("adddress", euserAddress);



  const CopyReferalId = (referalLink) => {
if(euserAddress){
  

  window.navigator.clipboard.writeText(referalLink).then(() => {
    alert(" Copied : " + referalLink)


  }).catch(err => { alert(err) })
}
else{
  alert("Connect wallet First")
}

  }


  return (
    <div className={`${zz.referalDiv}`}     >

      {/* Ref\eral   */}
      <div className={`${zz.referalCont} gap-2 p-2  d-flex align-items-center justify-content-between `}   >

        <input value={referralLink} className={`${zz.referalLink} `} readOnly  ></input>


        <button className={`${zz.referalCopy} fw-bold `} title='Copy Link'
          onClick={() => CopyReferalId(referralLink)}
        >
          Copy Link</button>
      </div>
      <p className={`${zz.referalText}    `}    >Share Link &  Get 10% on every successful refer</p>

      {/* Ref\eral   */}
    </div>
  )
}

export default Referral