import React from 'react'

const Footerlag = () => {
  return (
    <div className='footer_legpiece'  >
        Copyright ©  Sikka Coin 2023 
    </div>
  )
}

export default Footerlag