import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
// import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Navbar = () => {

  const navigate = useNavigate();


  const [isConnected, setConnection] = useState(false);
  const [address, setAddress] = useState(null);
  const [showNetworkMessage, setShowNetworkMessage] = useState(false);
  const location = useLocation();



  // home 

  const HomeErra = () => {
        navigate("/");
        setTimeout(()=>{
window.scrollTo(0,0)
        },100)
    }


  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
      } else {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
    } catch (error) {
      console.error('Failed to copy to clipboard:', error);
    }
  };

  const handleChainChange = (chainId) => {
    if (chainId === '0x38') {
      setConnection(true);
      setShowNetworkMessage(false);
    } else {
      setConnection(false);
      setShowNetworkMessage(true);
    }
  };

  const walletConnection = async (e) => {
    if (e) {
      e.preventDefault();
    }
  
    if (window.ethereum && window.ethereum.isMetaMask) {
      try {
        window.ethereum.on('chainChanged', handleChainChange);
  
        const chainId = '0x38'; // Mumbai Matic testnet chain ID
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
  
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const connectedAddress = accounts[0];
        console.log('Connected Address:', connectedAddress);
        await copyToClipboard(connectedAddress);
        setAddress(connectedAddress);
        setConnection(true);
      } catch (error) {
        setConnection(false);
        setAddress(null);
        console.error('Failed to connect wallet:', error);
      }
    }
  };
  
  const disconnectWallet = async () => {
    try {
      if (window.ethereum && window.ethereum.isMetaMask) {
        const permissions = await window.ethereum.request({ method: 'wallet_getPermissions' });
        const hasEthAccountsPermission = permissions.some((permission) => permission.parentCapability === 'eth_accounts');

        if (hasEthAccountsPermission) {
          await window.ethereum.request({ method: 'wallet_clearPermissions', params: [{ eth_accounts: {} }] });
          setAddress(null);
          setConnection(false);
         
        } else {
          console.error('Eth accounts permission is not granted.');
        }
      } else {
        console.error('MetaMask is not available.');
      }
    } catch (error) {
      console.error('Failed to disconnect wallet:', error);
    }
  };



  // const logout = async () => {
  
  //   try {
  //     // Perform any additional logout logic here
  //     // For example, remove user data from localStorage and redirect the user
  //     toast('Wallet Diconnected Successfully');
  //     setAddress(null);
  //     setConnection(false);
  //     // Redirect the user to the login page or another appropriate destination
  //   } catch (error) {
  //     console.error('Failed to log out:', error);
  //   }
  // };



  useEffect(() => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      walletConnection();
    }
  }, []);



  useEffect(() => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum.on('chainChanged', handleChainChange);
      return () => window.ethereum.removeListener('chainChanged', handleChainChange);
    }
  }, [location.pathname]);



  return (
    <div>
      {showNetworkMessage && (
        <div className="cardWarning" style={{zIndex:"1516546999999999999999999999999999999999999999999999999999999"}}>
          <div className="cardWarning_content_box">
            <div className='cardWarning_content_box1'>
              <i className="fa-solid fa-globe"></i>
            </div>
            <p>Wallet is not Connected with BNB Network</p>
            <div className='cardWarning_content_box2'>
              <button onClick={(e) => walletConnection(e)}>Switch to BNB Network</button>
            </div>
          </div>
        </div>
      )}
      <div className="nav">
        <input type="checkbox" id="nav-check" />
        <div className="nav-header">
          <div className="nav-title pb-4">
            <Link to='/'>
              <img src={require('../Media/coin_icon.png')} alt='Icc_logo' />
            </Link>
          </div>
        </div>
        <div className="nav-btn">
          <label htmlFor="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>
        <div id='navnone' className="nav-links dnone">
          <NavLink className='me-4  blueit' to='/' onClick={()=>HomeErra()}>Home</NavLink>
          <NavLink className='me-4 blueit' to='/Staking'>Stake</NavLink>
          <button
            className='btn ps-5 pe-5 rounded-pill m-auto fs-4 SigniNBtn'
            onClick={isConnected ? disconnectWallet : walletConnection}
            title='Click to copy address'
          >
            {isConnected ? <>{address ? address.slice(0, 6) + '...' + address.slice(-4) : null}</> : 'Connect Wallet'}
          </button>
          {/* <button className='btn ps-5 pe-5 rounded-pill m-auto fs-4 SigniNBtn' onClick={logout}>Logout </button> */}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
