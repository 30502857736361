import React from 'react'
import Play from "../playwatch/Play"

import zss from "./Intro.module.css"


const Intro = () => {


return (
<>
<div  className={zss.Intro}   >
<div className={zss.IntroTopLayer} >

{/* 1 */}
<div className={`${zss.chik1} d-flex justify-content-center align-items-end  `} >
<img  className={zss.imgicc} alt='img' loading='lazy' src={require("../Media/coin.gif")}    ></img>
</div>

{/* 2 */}

<div className={zss.chik2} >
<Play></Play>
</div>


</div>
</div>

</>
  )
}

export default Intro