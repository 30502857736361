import { configureStore } from "@reduxjs/toolkit";
import kycSlice from "./Slices/KycComponent";
import SignupSlice from "./Slices/SignupSlice";
import userContreactAddressSlice from "./Slices/UserContractSlice";

const Store = configureStore({
    reducer : {
        KycVisibility : kycSlice.reducer,
        SignupVisibility : SignupSlice.reducer,
        userContreactAddressSlice: userContreactAddressSlice.reducer
    }
})



export default Store
