import React from 'react'
import as from "./TermsCondition.module.css"

const TermsCondition = () => {
  return (
    <div className={`${as.TermsConditionCont} text-white mt-5`}  >
        <h1 className='text-center text-white'   >Terms And Condition</h1>

<ul className={`${as.uls}`}    >
    <li>There is no admin charge on staking & income Claiming </li>
    <li>There's 10% admin charge on unstaking & Emergency claiming</li>
<li>User can't do  emergency claim before 90 days.</li>
</ul>


    </div>
  )
}

export default TermsCondition