import { createSlice } from "@reduxjs/toolkit";


const userContreactAddressSlice = createSlice({
    name: "userContreactAddress",
    initialState: [],
    reducers : {
storeUserContractAddress(state,action){
    state.push(action.payload)
    console.log(state);
    console.log(action.payload);

},
getUserContractAddress(state,action){
    console.log(state);
    
return state;
}
    }
})



export default userContreactAddressSlice

export const {showSignup,hideSignUp} = userContreactAddressSlice.actions;
