// import styles from "./LogOut.module.css"
import React from 'react'
import styles from "./LogOut.module.css"



const LogOutSpin = (props) => {
    return (
        <div style={{
            width: "100vw", height: "100vh", position: "fixed", background: " #0a0000cd",
            opacity: "1",
             zIndex: "99999999999999999999999999999999999999999999999",
             color:"var(--golden)"
             }}
            id="fadelayer" className={`${styles.fadelayer}  d-flex  justify-content-center align-items-center display-4 text-info`}  >
 
            <span id={styles.TypingLogout} className='TypingLogout pe-4' style={{width:"auto",color:"var(--golden)"}} >{props.Staking}</span>

            <div style={{  opacity: "1", fontSize: "8rem",color:"var(--golden)" }} className="spinner-border LogOutSpinner " role="status">
                <span id={styles.spinn} className="visually-hidden ">Loading...</span>

            </div>
        </div>

    )
}

export default LogOutSpin